import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';

function CustomSVG() {
    const svgRef = React.useRef(null)
    const history = useHistory();
    React.useEffect(()=>{
        if(svgRef.current){
          const firsts = svgRef.current.querySelectorAll(".first-animation")
            for (let i = 0; i < firsts.length; i++) {
                    setTimeout(() => {
                          firsts[i].classList.add("first-start")
                    }, i * 80)
            }

            let isCnt = firsts.length
          firsts.forEach((first)=>{
            first.addEventListener("animationend",(e)=>{
              isCnt--
              if(isCnt===0){
                const seconds = svgRef.current.querySelectorAll(".second-animation")

                seconds.forEach((second)=>{
                  second.classList.add("second-start")
                })

              }
            })
          })
        }
    },[svgRef])

  const handlerMovePrev = () => {
    history.push("/prev")
  }

  return (
    <Wrapper ref={svgRef} xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"
         viewBox="0 0 926.62165 600.08495">
        <circle cx="150.53395" cy="254.87249" r="24.56103" fill="#ffb8b8" />
        <polygon points="67.543 549.197 73.762 559.763 117.474 540.803 108.295 525.21 67.543 549.197" fill="#ffb8b8" />
        <path
          d="M206.09566,694.4297,218.343,715.23658l.0005.00084A15.38605,15.38605,0,0,1,212.888,736.302l-.43091.25362L192.4047,702.48857Z"
          transform="translate(-136.68918 -149.95753)" fill="#2f2e41" />
        <polygon points="139.6 587.26 151.86 587.259 157.692 539.971 139.598 539.972 139.6 587.26" fill="#ffb8b8" />
        <path d="M273.16223,733.21466l24.14379-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00146Z"
              transform="translate(-136.68918 -149.95753)" fill="#2f2e41" />
        <path
          d="M227.00063,708.56723a4.49318,4.49318,0,0,1-3.624-1.833l-11.82373-16.05859a4.49961,4.49961,0,0,1,1.12451-6.41016l52.614-35.1416-31.74659-83.47461L301.434,571.2518l2.28662,71.7334A36.04579,36.04579,0,0,1,285.67544,675.409l-56.42652,32.55371A4.486,4.486,0,0,1,227.00063,708.56723Z"
          transform="translate(-136.68918 -149.95753)" fill="#2f2e41" />
        <path
          d="M276.65933,727.617a4.49986,4.49986,0,0,1-4.48877-4.33984l-2.488-69.88184,30.80835-1.623-2.45313,70.38379a4.48729,4.48729,0,0,1-4.1997,4.333l-16.87281,1.11817C276.86294,727.6141,276.76089,727.617,276.65933,727.617Z"
          transform="translate(-136.68918 -149.95753)" fill="#2f2e41" />
        <path
          d="M309.29092,585.12875c-7.83838,0-35.21656-8.8164-44.72119-11.959-1.42188,1.13769-9.23316,6.97851-18.35108,5.91113-5.626-.66016-10.57886-3.84473-14.72095-9.46582-9.147-12.41406,2.15064-65.65137,13.239-108.126a34.26782,34.26782,0,0,1,40.52856-24.87988h0a34.57137,34.57137,0,0,1,26.08765,25.37793c2.08765,8.49805.29175,18.49414-1.01953,25.79395-.61646,3.43164-1.10352,6.14257-.937,7.47461.64355,5.14843,4.21875,85.8916,1.91992,89.33984C311.07143,584.96274,310.36343,585.12875,309.29092,585.12875Z"
          transform="translate(-136.68918 -149.95753)" fill="#6c63ff" />
        <path
          d="M280.616,585.27177a10.74269,10.74269,0,0,0,.9416-16.44569l.48534-97.84356-23.04478,3.8374,6.76925,94.97037A10.80091,10.80091,0,0,0,280.616,585.27177Z"
          transform="translate(-136.68918 -149.95753)" fill="#ffb8b8" />
        <path
          d="M285.946,533.81821H261.12417a4.51352,4.51352,0,0,1-4.5-4.459l-.60938-67.292a17.603,17.603,0,1,1,35.20411.043L290.4457,529.37A4.51547,4.51547,0,0,1,285.946,533.81821Z"
          transform="translate(-136.68918 -149.95753)" fill="#6c63ff" />
        <path
          d="M280.96926,429.28873c-11.24529.02059-21.85416-8.868-23.80286-19.94323a28.67148,28.67148,0,0,1,.91927-13.01372A34.95394,34.95394,0,0,1,306.021,374.379c3.00831,1.36131,5.97145,3.31645,7.3489,6.31741s.51535,7.19528-2.49791,8.54561c-1.96916.88242-4.44023.4491-6.18719,1.71573-2.50928,1.81937-1.86334,5.69129-.74286,8.58112s2.38776,6.38691.50947,8.85239c-1.75023,2.29739-5.24872,2.22609-8.00436,1.36137s-5.47557-2.31277-8.34864-2.01822c-4.1904.42961-7.20172,4.59773-7.7591,8.77306s.75474,8.34892,2.05018,12.35714Z"
          transform="translate(-136.68918 -149.95753)" fill="#2f2e41" />
        <path d="M395.68918,750.04247h-258a1,1,0,1,1,0-2h258a1,1,0,0,1,0,2Z"
              transform="translate(-136.68918 -149.95753)"
              fill="#ccc" />
        <path
          d="M1046.81082,584.50538H405.97232a16.51868,16.51868,0,0,1-16.5-16.5V172.85157a12.10185,12.10185,0,0,1,12.08789-12.08838h649.4535a12.31111,12.31111,0,0,1,12.29711,12.29736V568.00538A16.51867,16.51867,0,0,1,1046.81082,584.50538Z"
          transform="translate(-136.68918 -149.95753)" fill="#f2f2f2" />
        <path
          d="M1026.091,563.67306H428.84627c-8.755,0-15.87757-6.66993-15.87757-14.86817v-344.105c0-6.08008,5.278-11.02685,11.76563-11.02685H1030.008c6.59521,0,11.96069,5.02832,11.96069,11.20849V548.80489C1041.9687,557.00313,1034.8459,563.67306,1026.091,563.67306Z"
          transform="translate(-136.68918 -149.95753)" fill="#fff" />
        <path
          d="M1063.08109,174.90772H389.24076v-8.40039a16.57376,16.57376,0,0,1,16.56-16.5498H1046.521a16.57368,16.57368,0,0,1,16.56006,16.5498Z"
          transform="translate(-136.68918 -149.95753)" fill="#3f3d56" />
        <circle cx="282.64694" cy="12.5" r="4.28342" fill="#fff" />
        <circle cx="298.90575" cy="12.5" r="4.28342" fill="#fff" />
        <circle cx="315.16455" cy="12.5" r="4.28342" fill="#fff" />
        <path
          d="M713.29967,268.912H596.49153a6.56614,6.56614,0,0,1,0-13.13228H713.29967a6.56614,6.56614,0,1,1,0,13.13228Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M858.44587,268.912H741.63774a6.56614,6.56614,0,0,1,0-13.13228H858.44587a6.56614,6.56614,0,1,1,0,13.13228Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M858.44587,293.79419H655.24119a6.56613,6.56613,0,1,1,0-13.13227H858.44587a6.56613,6.56613,0,1,1,0,13.13227Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M713.29967,243.68419H596.49153a6.56614,6.56614,0,0,1,0-13.13227H713.29967a6.56614,6.56614,0,1,1,0,13.13227Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M777.5787,244.02978H744.40243a6.56614,6.56614,0,0,1,0-13.13228H777.5787a6.56614,6.56614,0,1,1,0,13.13228Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M629.66781,293.79419H596.49153a6.56614,6.56614,0,0,1,0-13.13227h33.17628a6.56614,6.56614,0,1,1,0,13.13227Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M713.29967,501.912H596.49153a6.56614,6.56614,0,0,1,0-13.13228H713.29967a6.56614,6.56614,0,1,1,0,13.13228Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M858.44587,501.912H741.63774a6.56614,6.56614,0,0,1,0-13.13228H858.44587a6.56614,6.56614,0,1,1,0,13.13228Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M858.44587,526.79419H655.24119a6.56613,6.56613,0,1,1,0-13.13227H858.44587a6.56614,6.56614,0,1,1,0,13.13227Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M713.29967,476.68419H596.49153a6.56613,6.56613,0,0,1,0-13.13227H713.29967a6.56613,6.56613,0,1,1,0,13.13227Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M777.5787,477.02978H744.40243a6.56614,6.56614,0,0,1,0-13.13228H777.5787a6.56614,6.56614,0,0,1,0,13.13228Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M629.66781,526.79419H596.49153a6.56613,6.56613,0,0,1,0-13.13227h33.17628a6.56614,6.56614,0,1,1,0,13.13227Z"
          transform="translate(-136.68918 -149.95753)" fill="#ccc" className="first-animation animation" />
        <path
          d="M682.793,431.41924a9.39692,9.39692,0,0,1-6.62352-2.7101l-43.90868-43.35929a9.1516,9.1516,0,0,1,0-13.02336l43.73124-43.18435a9.70171,9.70171,0,0,1,13.64354.043h0a9.689,9.689,0,0,1,.00019,13.68669l-32.49607,32.49664a5.41338,5.41338,0,0,0,0,7.64652l32.31978,32.32016a9.418,9.418,0,0,1-6.66648,16.08412Z"
          transform="translate(-206.68918 -149.95753)" fill="#6c63ff" className="second-animation animation" />
      <g className="second-animation animation" >
      <text transform="translate(483.62165 230.08495)"  alignmentBaseline="middle" > 2023 새 단장 중에 있습니다 </text>
      <text onClick={handlerMovePrev} className={"click"}  alignmentBaseline="middle" transform="translate(523.62165 250.08495)"> 이전 버전 확인하기 </text>
      </g>
      <path
          d="M752.75859,431.41924a9.418,9.418,0,0,1-6.66648-16.08412L778.41189,383.015a5.41338,5.41338,0,0,0,0-7.64652L745.91582,342.8718a9.67776,9.67776,0,0,1,.00019-13.68669h0a9.70169,9.70169,0,0,1,13.64353-.043l43.73124,43.18435a9.15157,9.15157,0,0,1,0,13.02336l-43.90867,43.35929A9.39891,9.39891,0,0,1,752.75859,431.41924Z"
          transform="translate(-66.68918 -149.95753)" fill="#6c63ff" className="second-animation animation" />
    </Wrapper>
  );
}

const firstAnimation = keyframes`
  0% {
    opacity: 0;
    scale: 2;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`

const secondAnimation = keyframes`
  0% {
    opacity: 0;
    scale: 1.5;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
`

const flickerAnimation = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
`


const Wrapper = styled.svg`
  //width="926.62165" height="600.08495"
  width: 926.62165px;
  height: 600.08495px;
   
  & .animation{
    opacity: 0;
    scale: 1;
    transform-origin: 50% 50%;
  }
    
    & .first-animation.first-start {
        animation: ${firstAnimation} 2s forwards;
    }
  
    & .second-animation.second-start{
      
      opacity: 1;
      transition-duration: 1s;
    }

  & text {
    font-family: 'SCDream';
  }
  
  & .click {
    font-size: 12px;
    fill: red;
    cursor: pointer;
      animation: ${flickerAnimation} .8s infinite;
  }

  @media ( max-width: 1050px ) {
    width: 826.62165px;
    height: 500.08495px;
  }

  @media ( max-width: 795px ) {
    width: 526.62165px;
    height: 300.08495px;
  }

  @media ( max-width: 535px ) {
    width: 326.62165px;
    height: 150.08495px;
  }
`

export default CustomSVG;